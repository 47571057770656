import {
    getSph3reData
} from "./utils/apicalls.js";

import { useEffect, useState } from "react";

import { Link } from "react-router-dom";


const TheSph3res = (props) => {

    const [tokens, setTokens] = useState([]);

    useEffect(() => {
        const caller = async () => {
            let t = await getSph3reData();
            setTokens(t);
        };
        caller().then(() => {});


    }, []);   

    const imageURL =
    "https://bafybeigg2gkg7x3dmmgm7bk4tmc6aunb74s6tzg6ohrsgvh3jgtxju2lta.ipfs.dweb.link/sph3re_image_thumb.jpg";

    return (
        <div>
            <br /> <br /> <br /> <br /><br />
            <div className="centertext">
                <h2 className="centertext">
                    We are the sph3res
                </h2>
                <br />
                White sph3res = shareable <br/>
                Grey sph3res = frozen<br /><br />
                <Link to="/Leaderboard" className="center">
                <button>
                    open leaderboard
                </button>
                </Link>
            </div>
            <br /> <br />
            {tokens.map(function(e,index){ return (
                <div key={`item__${index}`}>
                    <a href={"https://opensea.io/assets/0x1ad733d8196d9ffacaaa9eaddc0da07727a4f47b/" + e.tokenid} target="_blank">
                    {(parseInt(e.frozen) === 1) ? (
                        <div className="wrapASpheresmall" key={`item__${e.id}`} style={{
                            background:
                                "url(" +
                                imageURL +
                                ")",
                        }}>
                            <div className="tokenid" >
                            {e.tokenid}<br />
                                <div className="smallfont">
                                    {e.name}
                                </div>
                            </div>
                        </div>
                    ) : (
                        
                        <div className="wrapASpheresmall" key={`item__${e.id}`} style={{
                            background:
                                "url(" +
                                imageURL +
                                ")",
                        }}>
                            <div className="tokenid visible" >
                                {e.tokenid}<br />
                                <div className="smallfont">
                                    {e.name}
                                </div>
                            </div>
                        </div>
                    )}
                    </a>
                </div>
            )})}
            <div className="clearfix"></div>
            <br /><br /><br />
            <div className="footer">
                <a
                    href="https://twitter.com/sph3res"
                    target="_blank"
                    rel="noreferrer"
                >
                    Sph3res on Twitter
                </a>
                <br />
                <a href="https://etherscan.io/address/0x1ad733d8196d9ffacaaa9eaddc0da07727a4f47b" target="_blank">Contract on Etherscan</a>                    
                <br />
                <a href="https://opensea.io/collection/the-sph3res" target="_blank">Sph3res on OpenSea</a>
                <br />
                <a href="https://looksrare.org/collections/0x1aD733D8196D9FfaCaaA9eADdC0da07727A4f47B" target="_blank">Sph3res on Looksrare</a>
            </div>
            <br /><br /><br />
        </div>
    );
};

export default TheSph3res;
