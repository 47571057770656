import "../App.css";
import Header from "../Header";
import TheSph3res from "../TheSph3res";

import { useEffect, useState } from "react";

const TheSph3resApp = (props) => {

    useEffect(() => {
        const caller = async () => {
        };
        caller().then(() => {});

        return () => {};
    }, []);

    return (
        <div className="App">
            {
                <div>
                    <Header />
                    <TheSph3res />
                </div>
            }
        </div>
    );
};

export default TheSph3resApp;
