import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {
    BrowserRouter,
    Routes,
    Route
  } from "react-router-dom";
import LeaderboardApp from "./routes/LeaderboardRoute";
import APIdataApp from "./routes/APIdataRoute";
import TheSph3resApp from "./routes/TheSph3resRoute";




ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<App />} />
                <Route path="leaderboard" element={<LeaderboardApp />} />
                <Route path="apidata" element={<APIdataApp />} />
                <Route path="thesph3res" element={<TheSph3resApp />} />
            </Routes>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
