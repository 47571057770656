import { useEffect, useState } from "react";
import { Link } from "react-router-dom";


const Header = (props) => {
    return (
        <div>
            <div className="logo" id="logo">
                <Link to="/">
                    <img src="/l.png" alt="logo" />
                </Link>
            </div>
        </div>
    );
};

export default Header;
