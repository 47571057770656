import {
    showLeaderboard
} from "./utils/interact.js";

import { useEffect, useState } from "react";
import { Link } from "react-router-dom";


const LeaderBoardComponent = (props) => {

    const [amounts, setAmounts] = useState([]);

    useEffect(() => {
        const caller = async () => {
            let leaderboard = await showLeaderboard();

            setAmounts(leaderboard);
        };
        caller().then(() => {});


    }, []);
    
    useEffect(() => console.log(amounts), [amounts]);

    return (
        <div>
            <div className="container">
                <div class="centertext">
                <br /><br /><br /><br />
                <h2>Leaderboard</h2>
                <br />
                <Link to="/thesph3res">
                    <button>
                        see all sph3res
                    </button>
                </Link>
                <br /><br /><br />
                </div>
                
                { amounts?.length > 0 ? (
                    <div>
                    
                    { amounts?.map((item, index) => (
                        <div className="leaderboardline">
                            <div className="left">
                                {item.name}
                            </div>
                            <div className="right">
                                {item.score}
                            </div>
                        </div>
                    ))}

                    </div>

                ) : (
                    <div className="centertext">
                        Loading...
                    </div>

                ) }
                
                            

                <br /><br /><br /><br /><br /><br />
                <div className="footer">
                    <a
                        href="https://twitter.com/sph3res"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Sph3res on Twitter
                    </a>
                    <br />
                    <a href="https://etherscan.io/address/0x1ad733d8196d9ffacaaa9eaddc0da07727a4f47b" target="_blank">Contract on Etherscan</a>                    
                    <br />
                    <a href="https://opensea.io/collection/the-sph3res" target="_blank">Sph3res on OpenSea</a>
                    <br />
                    <a href="https://looksrare.org/collections/0x1aD733D8196D9FfaCaaA9eADdC0da07727A4f47B" target="_blank">Sph3res on Looksrare</a>
                </div>
                <br />
                <br />
            </div>
        </div>
    );
};

export default LeaderBoardComponent;
