import { useEffect, useState } from "react";
import {
    connectWallet,
    disconnectWallet,
    getBaseURI,
    shareSphereWithAsh,
    shareSphereWithETH,
    approveAsh,
    waitForOriginsMined,
    getClaims,
    getHasSpheres,
    getSpheres,
    getCurrentWalletConnected,
    ashAllowance,
    waitForSpheresShared,
    waitForAshApproved,
    getSupply,
} from "./utils/interact.js";

import { Link } from "react-router-dom";

const Sharer = (props) => {
    //State variables
    const [walletAddress, setWallet] = useState("");
    const [status, setStatus] = useState("");
    const [name, setName] = useState("");
    const [mintable, setMintable] = useState(false); //defines whether this user can actually mint something
    const [nameIsOk, setNameIsOk] = useState(false); //defines whether this user can actually mint something
    const [amount, setAmount] = useState(0);
    const [minted, setMinted] = useState(false);
    const [hasNotMinted, setHasNotMinted] = useState(true);
    const [finished, setFinished] = useState(false);
    const [hasSpheres, setHasSpheres] = useState(-1);
    const [spheres, setSpheres] = useState(-1);
    const [loadingSpheres, setLoadingSpheres] = useState(true);
    const [baseUri, setBaseUri] = useState(null);
    const [shareASphere, setShareASphere] = useState(false); //"share with ETH"
    const [shareWithAsh, setShareWithAsh] = useState(false);
    const [approveAshButton, setApproveAshButton] = useState(false);
    const [receiverAddress, setReceiverAddress] = useState("");
    const [isAddressOk, setIsAddressOk] = useState(false);
    const [sharedTokenId, setSharedTokenId] = useState(-1);
    const [hasShared, setHasShared] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [supply, setSupply] = useState(0);
    const [loginError, setLoginError] = useState(false);

    const imageURL =
        "https://bafybeigg2gkg7x3dmmgm7bk4tmc6aunb74s6tzg6ohrsgvh3jgtxju2lta.ipfs.dweb.link/sph3re_image_thumb.jpg";

    useEffect(() => {
        const caller = async () => {
            const { address, status } = await getCurrentWalletConnected();
            let baseuri = await getBaseURI();
            let supply = await getSupply();
            setBaseUri(baseuri);
            setLoadingSpheres(true);
            setMinted(false);
            setWallet(address);
            setStatus(status);
            setHasNotMinted(true);
            addWalletListener();
            setLoadingSpheres(true);
            setYourSpheres(address);
            setShareWithAsh(false);
            setShareASphere(false);
            setApproveAshButton(false);
            setIsAddressOk(false);
            setReceiverAddress("");
            setSharedTokenId(-1);
            setHasShared(false);
            setIsButtonDisabled(true);
            setSupply(supply);
            setLoginError(false);
        };
        caller().then(() => {});

        return () => {
            setAmount(0);
        };
    }, []);

    const connectWalletPressed = async () => {
        let walletResponse = await connectWallet();

        if (walletResponse.address === "") {
            setLoginError(true);
        } else {
            setLoginError(false);
        }

        setStatus(walletResponse.status);
        setWallet(walletResponse.address);
        setYourSpheres(walletResponse.address);
        setShareWithAsh(false);
        setShareASphere(false);
        setSharedTokenId(-1);
    };

    const setYourSpheres = async (wallet) => {
        setLoadingSpheres(true);

        const hasSpheres = await getSpheres(wallet);
        setSpheres(hasSpheres.ownedNfts);

        if (hasSpheres != -1) {
            setHasSpheres(hasSpheres.ownedNfts.length);
            setLoadingSpheres(false);
        } else {
            setHasSpheres(0);
            setLoadingSpheres(false);
        }
    };

    function addWalletListener() {
        if (window.ethereum) {
            window.ethereum.on("accountsChanged", (accounts) => {
                if (accounts?.length > 0) {
                    setWallet(accounts[0]);
                    setYourSpheres(accounts[0]);
                    setShareWithAsh(false);
                    setShareASphere(false);
                    setSharedTokenId(-1);
                } else {
                    setWallet("");
                }
            });

            window.ethereum.on("disconnect", () => {
                setWallet("");
                setStatus("");
                alert("disconnected");
            });
        } else {
            setStatus(
                "<p> 🦊 <a target='_blank' href='https://metamask.io/download.html'>You must install Metamask, a virtual Ethereum wallet, in your browser.</a></p>",
            );
        }
    }

    function isNameOk(name) {
        if (name) setNameIsOk(!(name.trim() === "" || name.length > 32));
    }

    function markAddressOk(address) {
        if (address) {
            if (address.startsWith("0x")) {
                setIsButtonDisabled(false);
                setIsAddressOk(true);
                setReceiverAddress(address);
            } else {
                setIsButtonDisabled(true);
                setIsAddressOk(true);
                setReceiverAddress("");
            }
        } else setIsAddressOk(false);
    }

    function initShareASphere(tokenid) {
        setShareASphere(true);
        setSharedTokenId(tokenid);
        setStatus("");
        setFinished(false);
    }

    function initShareWithAsh(tokenid) {
        setStatus("");
        setFinished(false);
        ashAllowance(walletAddress)
            .then((r) => {
                if (r === true) {
                    setApproveAshButton(false);
                } else {
                    setApproveAshButton(true);
                }

                setShareWithAsh(true);
                setSharedTokenId(tokenid);
            })
            .catch((err) => console.error(err));
    }

    const triggerShareASphere = async (
        address,
        tokenid,
        canshare,
        walletAddress,
    ) => {
        const { success, status, hash } = await shareSphereWithETH(
            address,
            tokenid,
            canshare,
            walletAddress,
        );

        if (status === "Error: Invalid address") {
        } else {
            setHasShared(true);

            if (success) {
                setStatus(status);
                waitForSpheresShared(hash, setHasShared, setFinished);
                setShareASphere(false);
            } else {
                setStatus("An error occured or the transaction was rejected.");
                console.log(
                    "An error occured or the transaction was rejected.",
                );
                setHasShared(false);
                setShareASphere(false);
            }
        }
    };

    const triggerShareASphereWithAsh = async (
        address,
        tokenid,
        canshare,
        walletAddress,
    ) => {
        const { success, status, hash } = await shareSphereWithAsh(
            address,
            tokenid,
            canshare,
            walletAddress,
        );

        if (status === "Error: Invalid address") {
        } else {
            setHasShared(true);

            if (success) {
                setStatus(status);
                waitForSpheresShared(hash, setHasShared, setFinished);
                setShareWithAsh(false);
            } else {
                setStatus("An error occured or the transaction was rejected.");
                console.log(
                    "An error occured or the transaction was rejected.",
                );
                setHasShared(false);
                setShareWithAsh(false);
            }
        }
    };

    const initApproveAsh = async (walletAddress) => {
        const { success, status, hash } = await approveAsh(walletAddress);

        if (success) {
            waitForAshApproved(hash, setApproveAshButton);
        }
    };

    return (
        <div className="Minter">
            <div className="walletAddress">
                <button id="walletButton" onClick={connectWalletPressed}>
                    {walletAddress.length > 0 ? (
                        String(walletAddress).substring(0, 8) +
                        "..." +
                        String(walletAddress).substring(38)
                    ) : (
                        <span>connect wallet</span>
                    )}
                </button>
                {loginError && (
                    <span className="loginerror">
                        Could not connect wallet. <br />
                        <span className="smallfont">
                            Do you have{" "}
                            <a href="https://metamask.io" target="_blank">
                                Metamask
                            </a>{" "}
                            installed? <br />
                            Right now only Metamask wallet is supported.
                        </span>
                    </span>
                )}
            </div>

            <div className="hero">
                <div className="buttons">
                    {walletAddress?.length > 0 ? (
                        <div>
                            <div className="loggedoutinfo">
                                <h3>live now: share a sph3re</h3>

                                {loadingSpheres && (
                                    <div className="fadeIn">
                                        <h2>loading your sph3res.</h2>
                                    </div>
                                )}

                                {!loadingSpheres && spheres !== -1 && (
                                    <h2>
                                        You own {hasSpheres} <br />
                                        sph3re{hasSpheres > 1 ? "s" : ""}.
                                    </h2>
                                )}

                                {!loadingSpheres && spheres === -1 && (
                                    <div className="fadeIn">
                                        <h2>
                                            You are not
                                            <br />
                                            worthy.
                                        </h2>
                                    </div>
                                )}
                            </div>
                        </div>
                    ) : (
                        <button
                            id="walletButton"
                            onClick={connectWalletPressed}
                        >
                            {walletAddress.length > 0 ? (
                                "Connected as " +
                                String(walletAddress).substring(0, 6) +
                                "..." +
                                String(walletAddress).substring(38)
                            ) : (
                                <span>connect wallet</span>
                            )}
                        </button>
                    )}
                </div>

                <svg
                    version="1.1"
                    id="circles"
                    x="0px"
                    y="0px"
                    viewBox="0 0 6000 6000"
                >
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M2999.7,48.1c200.6,0,401,20.2,595.7,60c189.8,38.8,376.4,96.8,554.8,172.2
            c175.1,74.1,344.1,165.8,502.1,272.5C4808.8,658.6,4956,780,5089.7,913.8c133.8,133.8,255.2,280.9,360.9,437.4
            c106.8,158,198.4,326.9,272.5,502.1c75.4,178.4,133.4,365,172.2,554.8c39.8,194.7,60,395.1,60,595.7s-20.2,401-60,595.7
            c-38.8,189.8-96.8,376.4-172.2,554.8c-74.1,175.1-165.8,344.1-272.5,502.1c-105.7,156.5-227.2,303.7-360.9,437.4
            c-133.8,133.8-280.9,255.2-437.4,360.9c-158,106.8-326.9,198.4-502.1,272.5c-178.4,75.4-365,133.4-554.8,172.2
            c-194.7,39.8-395.1,60-595.7,60s-401-20.2-595.7-60c-189.8-38.8-376.4-96.8-554.8-172.2c-175.1-74.1-344.1-165.8-502.1-272.5
            c-156.5-105.7-303.7-227.2-437.4-360.9c-133.8-133.8-255.2-280.9-360.9-437.4c-106.8-158-198.4-326.9-272.5-502.1
            c-75.4-178.4-133.4-365-172.2-554.8c-39.8-194.7-60-395.1-60-595.7s20.2-401,60-595.7c38.8-189.8,96.8-376.4,172.2-554.8
            c74.1-175.1,165.8-344.1,272.5-502.1c105.7-156.5,227.2-303.7,360.9-437.4c133.8-133.8,280.9-255.2,437.4-360.9
            c158-106.8,326.9-198.4,502.1-272.5c178.4-75.4,365-133.4,554.8-172.2C2598.7,68.3,2799.2,48.1,2999.7,48.1 M2999.7,34.1
            C1359.6,34.1,30,1363.7,30,3003.8s1329.6,2969.7,2969.7,2969.7s2969.7-1329.6,2969.7-2969.7S4639.8,34.1,2999.7,34.1L2999.7,34.1z
            "
                            />
                        </g>
                        <g>
                            <path
                                className="st0"
                                d="M3001.5,346.3c180.4,0,360.8,18.2,536,54c170.7,34.9,338.7,87.1,499.2,154.9
            c157.6,66.6,309.5,149.1,451.7,245.2c140.8,95.1,273.2,204.4,393.6,324.7s229.6,252.8,324.7,393.6
            c96,142.2,178.5,294.1,245.2,451.7c67.9,160.5,120,328.4,154.9,499.2c35.9,175.2,54,355.5,54,536s-18.2,360.8-54,536
            c-34.9,170.7-87.1,338.7-154.9,499.2c-66.6,157.6-149.1,309.5-245.2,451.7c-95.1,140.8-204.4,273.2-324.7,393.6
            c-120.3,120.3-252.8,229.6-393.6,324.7c-142.2,96-294.1,178.5-451.7,245.2c-160.5,67.9-328.4,120-499.2,154.9
            c-175.2,35.9-355.5,54-536,54s-360.8-18.2-536-54c-170.7-34.9-338.7-87.1-499.2-154.9c-157.6-66.6-309.5-149.1-451.7-245.2
            c-140.8-95.1-273.2-204.4-393.6-324.7c-120.3-120.3-229.6-252.8-324.7-393.6c-96-142.2-178.5-294.1-245.2-451.7
            c-67.9-160.5-120-328.4-154.9-499.2c-35.9-175.2-54-355.5-54-536s18.2-360.8,54-536c34.9-170.7,87.1-338.7,154.9-499.2
            c66.6-157.6,149.1-309.5,245.2-451.7c95.1-140.8,204.4-273.2,324.7-393.6c120.3-120.3,252.8-229.6,393.6-324.7
            c142.2-96,294.1-178.5,451.7-245.2c160.5-67.9,328.4-120,499.2-154.9C2640.7,364.5,2821,346.3,3001.5,346.3 M3001.5,332.3
            c-1476.4,0-2673.3,1196.9-2673.3,2673.3s1196.9,2673.3,2673.3,2673.3S5674.8,4482,5674.8,3005.6S4477.9,332.3,3001.5,332.3
            L3001.5,332.3z"
                            />
                        </g>
                    </g>
                </svg>
            </div>

            {!finished && hasShared && (
                <div className="hero finished">
                    <div
                        id="status"
                        dangerouslySetInnerHTML={{
                            __html: status,
                        }}
                    />
                </div>
            )}

            {finished && !hasShared && (
                <div className="hero finished">
                    <h3>Done! Your sph3re has been shared.</h3>
                    <h2> spread the message</h2>
                    <br />
                    <a className="button" href="https://twitter.com">
                        tweet about sph3res
                    </a>
                </div>
            )}

            <br />

            {!hasShared &&
                !loadingSpheres &&
                spheres !== -1 &&
                !shareASphere &&
                !shareWithAsh &&
                !finished && (
                    <div className="fadeIn">
                        <div className="shareSpheres">
                            {hasSpheres > 0 && (
                                <div>
                                    <h2>your sph3res:</h2>

                                    {spheres?.map((item, index) => (
                                        <div key={`item__${index}`}>
                                            {item.frozen ? (
                                                <div
                                                    className="wrapASphere"
                                                    key={`item__${index}__${parseInt(
                                                        item.id.tokenId,
                                                    )}`}
                                                    style={{
                                                        background:
                                                            "url(" +
                                                            imageURL +
                                                            ")",
                                                    }}
                                                >
                                                    <div className="tokenid">
                                                        {parseInt(
                                                            item.id.tokenId,
                                                        )}{" "}
                                                        <br />
                                                    </div>
                                                </div>
                                            ) : (
                                                <div
                                                    className="wrapASphere sharehover"
                                                    key={`item__${index}__${parseInt(
                                                        item.id.tokenId,
                                                    )}`}
                                                    style={{
                                                        background:
                                                            "url(" +
                                                            imageURL +
                                                            ")",
                                                    }}
                                                >
                                                    <div className="tokenid visible">
                                                        {parseInt(
                                                            item.id.tokenId,
                                                        )}{" "}
                                                        <br />
                                                    </div>
                                                    <span className="title">
                                                        share using
                                                    </span>
                                                    <span
                                                        className="shareableleft smallfont sharebutton"
                                                        onClick={() =>
                                                            initShareWithAsh(
                                                                parseInt(
                                                                    item.id
                                                                        .tokenId,
                                                                ),
                                                            )
                                                        }
                                                    >
                                                        <b>ASH</b>
                                                    </span>
                                                    <span
                                                        className="shareableright smallfont sharebutton"
                                                        onClick={() =>
                                                            initShareASphere(
                                                                parseInt(
                                                                    item.id
                                                                        .tokenId,
                                                                ),
                                                            )
                                                        }
                                                    >
                                                        <b>ETH</b>
                                                    </span>
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            )}

                            {hasSpheres === 0 && (
                                <div>
                                    <h2>your sph3res:</h2>
                                    <br />
                                    You do not own a sph3re yet.
                                    <br />
                                    <br />
                                    <a
                                        href="https://opensea.io/collection/the-sph3res"
                                        target="_blank"
                                    >
                                        Get one on OpenSea
                                    </a>
                                    <br />
                                    <br />
                                    <a
                                        href="https://looksrare.org/collections/0x1aD733D8196D9FfaCaaA9eADdC0da07727A4f47B"
                                        target="_blank"
                                    >
                                        Get one on Looksrare
                                    </a>
                                </div>
                            )}

                            <div className="clearfix" />
                        </div>
                    </div>
                )}

            {loadingSpheres && (
                <div className="fadeIn">
                    <div className="shareSpheres">
                        <h2>your sph3res:</h2>
                        <br />
                        <br />
                        <p>Loading...</p>
                        <br />
                        <br />
                        <div className="clearfix" />
                    </div>
                </div>
            )}

            {shareWithAsh && !hasShared && (
                <div className="fadeIn">
                    <div className="container textcenter">
                        <br />
                        <br />
                        <br />
                        <span
                            className="smallbutton buttonhover"
                            onClick={() => {
                                setShareWithAsh(false);
                                setSharedTokenId(-1);
                            }}
                        >
                            go back{" "}
                        </span>
                        <hr className="smallhr" />
                        <br />
                        Who do you want to share your sph3re with?
                        <br />
                        <br />
                        <input
                            type="text"
                            placeholder="enter wallet address"
                            className="input"
                            onChange={(event) =>
                                markAddressOk(event.target.value)
                            }
                        />
                        <br />
                        <br />
                        <br />
                        <br />
                        {approveAshButton ? (
                            <div
                                className="button buttonhover"
                                onClick={() => initApproveAsh(walletAddress)}
                            >
                                Approve Ash
                                <div className="smallfont">
                                    Click here to approve ASH in your wallet.
                                </div>
                            </div>
                        ) : (
                            <button
                                className="button buttonhover"
                                onClick={() =>
                                    triggerShareASphereWithAsh(
                                        receiverAddress,
                                        sharedTokenId,
                                        isAddressOk,
                                        walletAddress,
                                    )
                                }
                                disabled={isButtonDisabled}
                            >
                                share this sph3re (5 ASH)
                            </button>
                        )}
                        <br />
                        <br />
                        <div className="smallfont">
                            Important: Please note, that you can only share your
                            sph3re
                            <br />
                            - with other wallets, not with yourself.
                            <br />
                            - with wallets that do not hold a sph3re.
                            <br />
                            - with wallets that did not already receive a shared
                            sph3re.
                            <br />
                            - if the maximum supply of 1024 is not yet reached.
                            <br />
                            <br />
                            If you see that the gas price in your MetaMask
                            wallet is extremely high, this means that the wallet
                            you're trying to share with is not valid.
                        </div>
                    </div>
                </div>
            )}

            {shareASphere && !hasShared && (
                <div className="fadeIn">
                    <div className="container textcenter">
                        <br />
                        <br />
                        <br />
                        <span
                            className="smallbutton buttonhover"
                            onClick={() => {
                                setShareASphere(false);
                                setSharedTokenId(-1);
                            }}
                        >
                            go back{" "}
                        </span>
                        <hr className="smallhr" />
                        <br />
                        Who do you want to share your sph3re with?
                        <br />
                        <br />
                        <input
                            type="text"
                            placeholder="enter wallet address"
                            className="input"
                            onChange={(event) =>
                                markAddressOk(event.target.value)
                            }
                        />
                        <br />
                        <br />
                        <br />
                        <br />
                        <button
                            className="button buttonhover"
                            onClick={() =>
                                triggerShareASphere(
                                    receiverAddress,
                                    sharedTokenId,
                                    isAddressOk,
                                    walletAddress,
                                )
                            }
                            disabled={isButtonDisabled}
                        >
                            share this sph3re (0.05 ETH)
                        </button>
                        <br />
                        <br />
                        <div className="smallfont">
                            Important: Please note, that you can only share your
                            sph3re
                            <br />
                            - with other wallets, not with yourself.
                            <br />
                            - with wallets that do not hold a sph3re.
                            <br />
                            - with wallets that did not already receive a
                            shared sph3re.
                            <br />
                            - if the maximum supply of 1024 is not yet reached.
                            <br />
                            <br />
                            If you see that the gas price in your MetaMask
                            wallet is extremely high, this means that the wallet
                            you're trying to share with is not valid.
                        </div>
                    </div>
                </div>
            )}

            <br />
            <br />
            <br />
            <hr />
            <div className="centertext">
                <h3>
                    The sph3res are live.
                    <br />
                    <br />
                    There are currently 
                    <Link to="/thesph3res">
                    <strong> {supply} sph3res </strong>
                    </Link>
                     in
                    existence.
                </h3>
                <br />
                <br />
                <Link to="/leaderboard" className="button">
                    open leaderboard
                </Link>
            </div>
        </div>
    );
};

export default Sharer;
