const Story = (props) => {
    return (
        <div>
            <div className="container">
                This digital performance borrows the teachings of sacred
                geometry to explore the creative potential of the sphere and the
                complexity that resides within it. Through balance and order, we
                can reveal the creation of complexity.
                <br />
                <br />
                The rendition of universal geometry in this virtual installation
                merely reveals the inherent complexity of the spheres by
                applying the teachings of Euclidean space. <br />
                <br />
                This process is achieved without artificial effects and follows
                the orders and efficacies of pure materials, which thus manifest
                in a cyber-simulated realm with light, atmosphere, and aura.
                Just like the spheres, the individual’s true potential can only
                unfold in resonance with others.
                <br />
                Behold one of the basic, yet most complex geometrical bodies:
                the sph3re. <br />
                <br />
                <b>
                    This token can only reveal its true potential once shared.
                </b>
                <br />
                <br />
                A purpose is to create new ash holders but you may use a sph3re
                to reward existing ashlings. <br />
                <br />
                The choice is yours.
                <hr />
                <h3>origin cycle</h3>
                <h2>the beginning</h2>
                <p>
                    The Sph3res of Ash collectors were captured in a snapshot
                    whitelist.
                    <br />
                    <br />
                    <b>The snapshot date was taken on: 10th February 2022 - 20:00 CET</b>
                    <br />
                    <br />
                    For every Sph3re of Ash they held, they were able to mint
                    2 origin sph3res, resulting in a total of{" "}
                    <b>42 origin sph3res</b>.<br />
                    <br />
                    These collectors initiated the first cycle by naming and
                    minting the origin sph3res.
                </p>
                <br />
                <div className="wrapashcollection">
                    <div className="p50">
                        <a
                            href="https://opensea.io/assets/0xa249a85fbaa120d75fc1e79bc6d6cb85a96d0b11/1"
                            target="_blank"
                        >
                            <img
                                src="/thecreator.png"
                                className="ashcollection"
                                alt=""
                            />
                            <br />
                            Sph3res of Ash: The Creator
                        </a>
                    </div>
                    <div className="p50">
                        <a
                            href="https://opensea.io/collection/sph3res-of-ash"
                            target="_blank"
                        >
                            <img
                                src="/sph3resofash.png"
                                className="ashcollection"
                                alt=""
                            />
                            <br />
                            Sph3res of Ash Collection
                        </a>
                    </div>
                </div>
                <br />
                <hr />
                <h3>first cycle</h3>
                <h2>share a sph3re</h2>
                <p>
                    After all origin sph3res have been minted and named, sph3res
                    can now be duplicated by sharing them with other wallets.
                    Sharers cover the creation fees + gas for the person they
                    are sharing their sph3re with.
                    <br />
                    <br />
                    This means that:
                    <br />
                    <b>
                        You cannot mint a sph3re for yourself, only for someone
                        else.
                    </b>
                    <br />
                    <br />
                    A shared sph3re will duplicate, leaving both, the sender and
                    the recipient with a sph3re.
                    <br />
                    Such duplication only works by using the sph3res' sharing
                    mechanism.
                    <br />
                    <br />
                    Once a sph3re was shared, the initial sph3re cannot be
                    shared again. <br />
                    Sph3res cannot be shared with other sph3re holders, nor with
                    yourself.
                    <br />
                    At every moment, sph3res can be bought and transferred
                    through the secondary market, but will <b>not</b> duplicate
                    during such transfers.
                    <br />
                    <br />
                    The sharing process may repeat until the total amount of
                    1024 sph3res has been created.
                    <br />
                    <br />
                    By following every individually named origin sph3re, a
                    leaderboard will determine which origin sph3res were shared
                    the most.
                    <br />
                    <br />
                    <b>#WeAcceptAsh</b>
                    <br />
                    Each sharer can choose to gift their sph3res to ash holders
                    or to people that don't have ash yet.
                    <br />
                    <br />
                </p>
            </div>


            <img src="/explainer.jpg" className="p80" />

            <div className="container">
                <hr />
                <h3>second cycle</h3>
                <h2>
                    shar̵̨̛̛͇̥̝͇͑͂͊̑͂ę̶͎̲͚̻͒͒͒̈̿͒̂̇̋̆͌͠ a s<s>p</s>h<s>3re</s>
                </h2>
                <p>
                    The sé̸̢̦̭̱̼͓͓̏͋͌͒́̽͘͠c̴̡̧̞̯͕̼͖̟̭͔̳̊́̉̏̈́̅͗̌̋̃́̌̓̇͜͠ơ̶̪̭̩̩̄̀͆̔͂͑̓̂̐͋̄̐̚̕͜n̸̟̺͍̹̣͉͉̣̟̝̿̒̿̃̑̕̚͘ͅd̵̡̧̢̛͕̟̭̤̩͙̞̗͌̿́̽̇̓͆̃͆̌̽̈́ ̸̧̗̤̼̪̘̰̞̱͍̣͑͊̔Ĉ̴̢̄̄͋̑̈̈́̊͋͊̓̅̕͝y̸̢̧͙̗̗̹͔̦̥̪̺̼͙̺̎̃̀̍͌̎ͅc̸̱̦͈̩̘̝̟͖̦̦̋̄̇̎̀̍̿̽̌̀͝͝l̶̥̳͉̼͈͓͍̣͇͍̎̔̅̐͛̎́̚͠ͅê̴̳̱̇͋̆̇́̽̒̕͜͜ ̶̡̭̪̓̾͑̿̀̈̆̑͐͛̚͜͜͝ḃ̵̧̛̼̤̹̺̼̏̓͛̓̈̓̂̓ë̶̢̩̯̯̝̙̹̜̝́̈̀̊̓́͜g̵̠͙͙̤̀͒͗̊̅̈́̋̇͗̚͘i̴̛̟̖̳͉͚͂̎̋͝n̸͉̤̰̜͖̳̆̅̄̾͂̕s̶͉͈̫̙̘̗̅̊̂̓ ̴̼̗͖͚͓̳͓̈́o̸͓̙̎̋̈́̐̎̔̀̾̋ņ̶͙̟̤͉͎̜̱̲͎̄͌̄͂͂̆̆̓̓̊̐̆č̴͙̯͔̖͕̠̮͉͙̞͐̏͒̌̚ê̴̛̛͎̹͎͈̰̤̙̄̊̄͊͊̊̀̾̚͜͝͝͠ͅͅ ̶̻̟̮͍̤̺͚́̒͊̿̈́͌̃͐̌̄͘á̷̟̪̣͈͍̺͎͈̟̮̄́̇͑̎̾̚ļ̷̗̱̪͕̞̫͎͕̆̒̍̽̎͠l̷̡̧̢̠̦̹͙̥̻̻̾̎͗̌͌̀͘͝ͅ ̷̪̞̹̰̼̦̠̱̅̅̌̓͌́͜͝ͅt̵̨͇̺͕͙͉̪̹̭̪̳͐̇̊ḣ̷̢͕̗͙̣͎̹̮͇̝͖͈̲̗̠͗̉̄͂͌̔͝ę̶̡̖͖͙̮̳̬̥̀͑̅̓͗̕ ̴̛͗̑̄́̋͐͛̽͒́͘͘͜s̵̡̨͓̠̺̝͖̥͈̱̮̈́͛̓͌͆̈́͂͂̒͝p̶̛̹͋́̈́͐͂̅͗̎́͊͊̉̑̐h̶̝̪̫͎͙̳͇̟͉̠̓͑̽͊͆̐͋̈́͜͜͝3̴̟̙͍͛̅͂ͅr̶͖̹̎̒̆̔̂̏̄͜ͅe̵̜̩̟̋̀͂̂̌̇̕s̸͙͉̲̽̽͝ ̴̧̬̹̤͙͑̒́ḩ̵̪̫̭͈̦̼̼̗̳̝̂̃̑a̵̡͎̫̝̰̺͖̣̝̝̼̞͔̲̍̒̈́̆͌͒̒͋̽̏͆̕v̵̡̨̫͈̥͕̫̜̠̥̟̗̬̜̬̓͆͝e̵̛̻̰͛̈́͋̏̓̉͗͐͑̾͑̿̋͒ ̸̡͔̰̟͕̘̼̿̋̀͐̈́̏̓̋̌̐̓̾̉̓b̴̼̰̟̩̟̈́̄̌̔̐̿̅͋̄̀͆̆̄̿̚ẹ̷͔̑̊́̐̄͆̎̈e̸̝͚͖̥̟̪͊̿̀̌̒͋̈́̀̑́̀͌͘ņ̶̡̛̛̳̭̺̜̭̯͖̣͇͆̄̔̊̾͛̄̔̂͘͝͝
                    ̷̧̭̲̻͚̼̤̦͎̥̰̟̙̈́̑̔̚ͅͅd̶̨͕̯̜̜̟͖̤̞̝͋̌̔͌̽̀̀̀͋̄̃͆͘͘͝i̴̢̙̤̍̎̓̎̂̀̏͝s̵̹̣̰̙͓͕͎̦͇͔̗̆̽͘͝ͅt̸̢̪̹̼̖̲̥̙̳̬͉̠̟͛̔͋̚r̸̛̥͌̓͑̓́̒ī̸̡̛̛̞̳̑̅͌́̍̀̐͝b̶̢͇͚̘̣̹̝͕͉̬̳͍̻͆ữ̸̢̖̗̥̰̖̭̌̆̽̋̂̎̀͘͘͠͝t̴̢̛̛̥͙̝̣͔̥͎̟̣̲̾͆͊͐̑̄̈ͅͅȅ̷͔̆̇͐́̈̄̐̍̈́̿̕̚͠d̷̡͚͂̓̓͗̆͝͝.̶̛̘̣͓̲̰̞͎̟̋̊́͌̏̉́̂̉̂̎̆͠͝ <br />
                </p>
                <br />
                Collectors understand{" "}
                <span className="errorline">leaderboards.</span>
                <div className="errorline" />
                <div className="errorline" />
                <div className="errorline" />
                <div className="errorline" />
                <br />
                <div className="errorline" />
                <div className="errorline" />
                <div className="errorline">
                    With the second cycle, rewards will be{" "}
                </div>
                <br />
                <div className="errorline" />
                <br />
                <hr />
                <i>
                    Individuation is only possible with people, through people.{" "}
                    <br />
                    You must realize that you are a link in a chain, that you
                    are not an electron suspended somewhere in space or
                    aimlessly drifting through the cosmos. <br />
                    <b>~C.G. Jung</b>
                </i>
                <hr />
                <h1>FAQ</h1>
                <div className="smallfont">
                    <b>What does it mean to share a sph3re?</b>
                    <br />
                    Sph3res have a special minting mechanism: You cannot mint a
                    sph3re for yourself, only for someone else. <br />
                    In other words, “sharing a sph3re” means that you create a
                    new sph3re for somebody else, while keeping your own.
                    <br />
                    Once your sph3re has been shared once, it cannot be shared
                    again.
                    <br />
                    Note that this is also true for sph3res that were acquired
                    on a secondary market: A sph3re that has already been shared
                    by anybody, cannot be shared again.
                    <br />
                    Furthermore, sph3res can only be shared:
                    <br />
                    - with other wallets, not with yourself.
                    <br />
                    - with wallets that do not hold a sph3re.
                    <br />
                    - with wallets that did not already receive a shared sph3re.
                    <br />
                    - if the maximum supply of 1024 is not yet reached.
                    <br />
                    <br />
                    <br />
                    <b>Why share a sph3re?</b>
                    <br />
                    The sph3res will create new Ash holders through various
                    interactive cycles. <br />
                    Sharing sph3res means growing the Ash community. So before
                    you share a sph3re with a person that does not hold Ash yet,
                    speak to them. Educate them about Ash and help them through
                    the process of the sph3res. This is a community-driven
                    project, so communication is highly encouraged.
                    <br />
                    <br />
                    <b>Are there gas fees?</b>
                    <br />
                    Yes, in order to share a sph3re with somebody you will have
                    to pay regular gas fees in ETH. <br />
                    <br />
                    <b>How much does it cost to share a sph3re?</b>
                    <br />
                    Each sender covers the gas fees as well as a mint price for
                    the person they are sending the sph3re to. Technically, you
                    are minting the sph3re for somebody else.
                    <br />
                    <br />
                    <b>How does the share mechanism work?</b>
                    <br />
                    If you share a sph3re with a friend using the share
                    mechanism, they receive a duplicate of your sph3re. Every
                    recipient can only share their sph3re one time. Once it has
                    been shared, the share mechanism cannot be used a second
                    time for that sph3re.
                    <br />
                    <br />
                    <b>Which wallets are supported on the website?</b>
                    <br />
                    Right now, this website only supports connections through <a href="https://metamask.io/" target="_blank" >Metamask</a>.
                    <br />
                    <br />
                    <b>How do I get a sph3re?</b>
                    <br />
                    Collectors of the Sph3res of Ash will be able to mint and
                    name origin sph3res to kick off this event. <br />
                    <br />
                    For all the others, the only ways to get a sph3re are:
                    <br />
                    - if somebody shares a sph3re with you.
                    <br />
                    - if you acquire a sph3re on the secondary market.
                    <br />
                    - if a sph3re is transferred to you.
                    <br />
                    <br />
                    How you achieve this is up to you.
                    <br />
                    <br />
                    <b>Why do the sph3res have different names?</b>
                    <br />
                    Only the initial origin sph3res can be named. The names of
                    the origin sph3res will be used to track how often sph3res
                    of one name were shared.
                    <br />
                    <br />
                    <b>
                        What happens if I send a sph3re to another wallet
                        without using the share mechanism?
                    </b>
                    <br />
                    If a sph3re is sent to another wallet without using the
                    sharing mechanism (i.e. sell or transfer via opensea or
                    similar), the sharing mechanics won’t be triggered and the
                    sph3re won’t be duplicated. You will thus not receive a copy
                    of the sph3re you shared.
                    <br />
                    So you can always transfer or sell a sph3re to another
                    wallet, but in that case, the sph3re will be lost to you.
                    <br />
                    This is true for shareable and for non-shareable sph3res.
                    <br />
                    <br />
                    <b>What is Ash and who is Pak?</b>
                    <br />
                    <a
                        href="https://frens.page/the0wl.eth"
                        target="_blank"
                        rel="noreferrer"
                    >
                        @jonnythe0wl
                    </a>{" "}
                    put together an amazing collection of resources to learn
                    about Pak and Ash for those that are new to the show:{" "}
                    <a
                        href="https://neon.page/muratpak"
                        target="_blank"
                        rel="noreferrer"
                    >
                        neon.page/muratpak
                    </a>
                    <br />
                    <br />
                    <b>Twitter </b>
                    <br />
                    When tweeting about the sph3res, you can use use
                    #ShareASph3re. Tagging{" "}
                    <a
                        href="https://twitter.com/Sph3res"
                        target="_blank"
                        rel="noreferrer"
                    >
                        @sph3res
                    </a>{" "}
                    is highly appreciated x3
                    <br />
                </div>
                <br />
                <br />
                <div className="footer">
                    <a
                        href="https://twitter.com/sph3res"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Sph3res on Twitter
                    </a>
                    <br />
                    <a href="https://etherscan.io/address/0x1ad733d8196d9ffacaaa9eaddc0da07727a4f47b" target="_blank">Contract on Etherscan</a>                    
                    <br />
                    <a href="https://opensea.io/collection/the-sph3res" target="_blank">Sph3res on OpenSea</a>
                    <br />
                    <a href="https://looksrare.org/collections/0x1aD733D8196D9FfaCaaA9eADdC0da07727A4f47B" target="_blank">Sph3res on Looksrare</a>
                </div>
                <br />
                <br />
            </div>
        </div>
    );
};

export default Story;
