import {
    setSph3reData
} from "./utils/apicalls.js";

import { useEffect, useState } from "react";


const APIdata = (props) => {

    const [data, setData] = useState([]);

    useEffect(() => {
        const caller = async () => {
            await setSph3reData();
            setData(1);
        };
        caller().then(() => {});


    }, []);   


    return (
        <div>
            {data}
        </div>
    );
};

export default APIdata;
