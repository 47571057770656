import axios from "axios";

require("dotenv").config();
const alchemyKey = process.env.REACT_APP_ALCHEMY_KEY;
const { createAlchemyWeb3 } = require("@alch/alchemy-web3");
const web3 = createAlchemyWeb3(alchemyKey);

//For getNFTS
const apiKey = process.env.REACT_APP_ALCHEMY_KEY2;
const baseURL = `https://eth-mainnet.g.alchemy.com/v2/${apiKey}/getNFTs/`;

const contractABI = require("../contract-abi.json");
const contractABIAsh = require("../contract-abi-ash.json"); //TODO Update Ash ABI

const contractAddress = "0x1aD733D8196D9FfaCaaA9eADdC0da07727A4f47B";
const ashAddress = "0x64d91f12ece7362f91a6f8e7940cd55f05060b92";
let number = new web3.utils.BN("2**256-1");
const ashAllowancePrice = web3.utils.toWei(number, "ether");

const ashPrice = String(web3.utils.toWei("5", "ether"));
const ethPrice = String(web3.utils.toWei("0.05", "ether"));

export const connectWallet = async () => {
    if (window.ethereum) {
        try {
            const addressArray = await window.ethereum.request({
                method: "eth_requestAccounts",
            });
            const obj = {
                status: "",
                address: addressArray[0],
            };

            return obj;
        } catch (err) {
            return {
                address: "",
                status: err.message,
            };
        }
    } else {
        return {
            address: "",
            status: (
                <div>
                    <p>
                        {" "}
                        🦊{" "}
                        <a
                            target="_blank"
                            href={`https://metamask.io/download.html`}
                        >
                            You must install Metamask, a virtual Ethereum
                            wallet, in your browser.
                        </a>
                    </p>
                </div>
            ),
        };
    }
};

export const disconnectWallet = async () => {};

export const getCurrentWalletConnected = async () => {
    if (window.ethereum) {
        try {
            const addressArray = await window.ethereum.request({
                method: "eth_accounts",
            });

            if (addressArray.length > 0) {
                return {
                    address: addressArray[0],
                    status: "",
                };
            } else {
                return {
                    address: "",
                    status: "connect wallet.",
                };
            }
        } catch (err) {
            return {
                address: "",
                status: "😥 " + err.message,
            };
        }
    } else {
        return {
            address: "",
            status: (
                <div>
                    <p>
                        {" "}
                        🦊{" "}
                        <a
                            target="_blank"
                            href={`https://metamask.io/download.html`}
                        >
                            You must install Metamask in your browser.
                        </a>
                    </p>
                </div>
            ),
        };
    }
};

export const getClaims = async (address) => {
    if (address !== "") {
        window.contract = await new web3.eth.Contract(
            contractABI,
            contractAddress,
        );

        try {
            return await window.contract.methods
                .getOriginClaims(address)
                .call(function (error, result) {
                    if (!error) return result;
                    else return -1;
                });
        } catch (error) {
            console.log("Error with origins", error);
            return 0;
        }
    }
};

export const getHasSpheres = async (address) => {
    if (address !== "") {
        window.contract = await new web3.eth.Contract(
            contractABI,
            contractAddress,
        );

        try {
            return await window.contract.methods
                .balanceOf(address)
                .call(function (error, result) {
                    if (!error) return result;
                    else return -1;
                });
        } catch (error) {
            console.log("Error with balance", error);
            return 0;
        }
    }
};

/*
export const getSpheres = async (address) => {
    if (address !== "") {
        window.contract = await new web3.eth.Contract(
            contractABI,
            contractAddress,
        );

        let supply = await window.contract.methods
            .totalSupply()
            .call(function (error, result) {
                if (!error) return result;
                else return -1;
            });

        let allspheres = [];

        for (let i = 1; i <= supply; i++) {
            let found = await window.contract.methods
                .ownerOf(i)
                .call(function (error, result) {
                    if (!error) return result;
                    else return -1;
                });

            if (found.toLowerCase() == address.toLowerCase()) {
                let isFrozen = await window.contract.methods
                    .getIsSphereFrozen(i)
                    .call(function (error, result) {
                        if (!error) return result;
                        else return -1;
                    });

                let finalvar = {};
                finalvar.tokenid = i;

                if (isFrozen) {
                    finalvar.frozen = true;
                } else {
                    finalvar.frozen = false;
                }

                allspheres.push(finalvar);
            }
        }

        return allspheres;
    }

    return -1;
};
*/

export const getSpheres = async (address) => {
    if (address !== "") {
        // replace with the wallet address you want to query for NFTs
        const ownerAddr = address;
        const contractAddr = contractAddress;

        let config = {
            method: "get",
            url: `${baseURL}?owner=${ownerAddr}&contractAddresses[]=${contractAddr}`,
        };

        return await axios(config)
            .then(async (response) => {
                let spheresArray = [];
                for (let i = 0; i < response.data.ownedNfts.length; i++) {
                    let frozen = await isFrozen(
                        response.data.ownedNfts[i].id.tokenId,
                    );

                    spheresArray.push({
                        ...response.data.ownedNfts[i],
                        frozen: frozen,
                    });
                }
                return { ...response.data, ownedNfts: spheresArray };
            })
            .catch((error) => console.log("Error in getSpheres", error));
    }

    return -1;
};

export const isFrozen = async (i) => {
    return await window.contract.methods
        .getIsSphereFrozen(i)
        .call(function (error, result) {
            if (!error) return result;
            else return -1;
        })
        .then((isFrozen) => !!isFrozen);
};

export const approveAsh = async (address) => {
    window.contract = await new web3.eth.Contract(contractABIAsh, ashAddress);

    //set up your Ethereum transaction
    const transactionParameters = {
        to: ashAddress, // Required except during contract publications.
        from: window.ethereum.selectedAddress, // must match user's active address.
        data: window.contract.methods
            .approve(contractAddress, ashAllowancePrice)
            .encodeABI(), //make call to NFT smart contract
    };

    //sign the transaction via Metamask
    try {
        const txHash = await window.ethereum.request({
            method: "eth_sendTransaction",
            params: [transactionParameters],
        });
        return {
            success: true,
            status: "",
            hash: txHash,
        };
    } catch (error) {
        console.log("ERROR", error);
        return {
            success: false,
            status: "Error: " + error.message,
            hash: "",
        };
    }
};

export const waitForAshApproved = async (hash, setApproveAshButton) => {
    let counter = 0;

    const interval = setInterval(function () {
        web3.eth.getTransactionReceipt(hash).then((res) => {
            counter++;
            if (res) {
                clearInterval(interval);
                setApproveAshButton(false);
            }

            if (counter > 1000) {
                alert(
                    "Something went wrong. Your transaction seems to be stuck. Please check your wallet.",
                );
                clearInterval(interval);
                setApproveAshButton(true);
            }
        });
    }, 2000);
};

export const ashAllowance = async (address) => {
    window.contract = await new web3.eth.Contract(contractABIAsh, ashAddress);

    return await window.contract.methods
        .allowance(address, contractAddress)
        .call()
        .then((result) => {
            if (parseInt(result) >= parseInt(ashPrice)) {
                return true;
            } else return false;
        });
};

export const shareSphereWithAsh = async (
    address,
    tokenid,
    canshare,
    walletAddress,
) => {
    if (web3.utils.isAddress(address) && canshare) {
        window.contract = await new web3.eth.Contract(
            contractABI,
            contractAddress,
        );

        //set up your Ethereum transaction
        const transactionParameters = {
            to: contractAddress, // Required except during contract publications.
            from: walletAddress, // must match user's active address.
            data: window.contract.methods
                .shareSphereWithAsh(address, tokenid)
                .encodeABI(), //make call to NFT smart contract
        };

        //sign the transaction via Metamask
        try {
            const txHash = await window.ethereum.request({
                method: "eth_sendTransaction",
                params: [transactionParameters],
            });
            return {
                success: true,
                status:
                    "<br /><div className='loader center'><span /><span /></div><h3>Sharing your sph3re:</h3><h2><a href='https://etherscan.io/tx/" +
                    txHash +
                    "' target='_blank'>Track transaction on Etherscan</a></h2>",
                hash: txHash,
            };
        } catch (error) {
            return {
                success: false,
                status: "Error: " + error.message,
                hash: "",
            };
        }
    } else {
        return {
            success: false,
            status: "Error: Invalid address",
            hash: "",
        };
    }
};

export const shareSphereWithETH = async (
    address,
    tokenid,
    canshare,
    walletAddress,
) => {
    if (web3.utils.isAddress(address) && canshare) {
        window.contract = await new web3.eth.Contract(
            contractABI,
            contractAddress,
        );

        let val = web3.utils.toHex(ethPrice);

        const transactionParameters = {
            value: val,
            to: contractAddress,
            from: walletAddress,
            data: window.contract.methods
                .shareSphere(address, tokenid)
                .encodeABI(),
        };

        try {
            const txHash = await window.ethereum.request({
                method: "eth_sendTransaction",
                params: [transactionParameters],
            });
            return {
                success: true,
                status:
                    "<br /><div className='loader center'><span /><span /></div><h3>Sharing your sph3re:</h3><h2><a href='https://etherscan.io/tx/" +
                    txHash +
                    "' target='_blank'>Track transaction on Etherscan</a></h2>",
                hash: txHash,
            };
        } catch (error) {
            return {
                success: false,
                status: "Error: " + error.message,
                hash: "",
            };
        }
    } else {
        return {
            success: false,
            status: "Error: Invalid address",
            hash: "",
        };
    }
};

export const waitForSpheresShared = (hash, setHasShared, setFinished) => {
    let counter = 0;

    const interval = setInterval(function () {
        web3.eth.getTransactionReceipt(hash).then((res) => {
            counter++;
            if (res) {
                setFinished(true);
                setHasShared(false);
                clearInterval(interval);
            }

            if (counter > 1000) {
                setFinished(false);
                setHasShared(false);
                alert(
                    "Something went wrong. Your transaction seems to be stuck. Please check your wallet.",
                );
                clearInterval(interval);
            }
        });
    }, 2000);
};

export const getBaseURI = async () => {
    window.contract = await new web3.eth.Contract(contractABI, contractAddress);

    return await window.contract.methods
        .getBaseURI()
        .call(function (error, result) {
            if (!error) return result;
            else return -1;
        });
};

export const showLeaderboard = async () => {
    let names = [
        "the author",
        "hodl",
        "Omega",
        "eyes of the 0wl",
        "H2",
        "Ahoi!",
        "Unicorns",
        "travelers",
        "Team Lobster",
        "An",
    ];

    window.contract = await new web3.eth.Contract(contractABI, contractAddress);

    let amounts = [];

    for (let i = 0; i < names.length; i++) {
        let amount = await window.contract.methods
            .getSpheresPerName(names[i])
            .call(function (error, result) {
                if (!error) {
                    return result;
                } else return -1;
            });

        let o = { name: names[i], score: amount };

        amounts.push(o);
    }

    let newamounts = amounts.sort((a, b) => {
        return b.score - a.score;
    });

    return newamounts;
};

export const getSupply = async () => {
    window.contract = await new web3.eth.Contract(contractABI, contractAddress);

    return await window.contract.methods
        .getCurrentTokenId()
        .call(function (error, result) {
            if (!error) {
                return result;
            } else {
                return -1;
            }
        });
};
