import "../App.css";
import Header from "../Header";
import LeaderboardComponent from "../LeaderboardComponent";

import { useEffect, useState } from "react";

const LeaderboardApp = (props) => {

    useEffect(() => {
        const caller = async () => {
        };
        caller().then(() => {});

        return () => {};
    }, []);

    return (
        <div className="App">
            {
                <div>
                    <Header />
                    <LeaderboardComponent />
                </div>
            }
        </div>
    );
};

export default LeaderboardApp;
