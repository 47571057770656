
import "./App.css";
import Header from "./Header";
import Story from "./Story";
import Sharer from "./Sharer";


import { useEffect, useState } from "react";

const App = (props) => {
    useEffect(() => {
        const caller = async () => {};
        caller().then(() => {});


        return () => {};
    }, []);

    return (
        <div className="App">
            {
                <div>
                    <Header />
                    <Sharer />
                    <Story />
                </div>
            }
        </div>
    );

};


export default App;
