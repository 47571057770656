import axios from "axios";

require("dotenv").config();
const alchemyKey = process.env.REACT_APP_ALCHEMY_KEY;
const { createAlchemyWeb3 } = require("@alch/alchemy-web3");
const web3 = createAlchemyWeb3(alchemyKey);

const contractABI = require("../contract-abi.json");
const contractABIAsh = require("../contract-abi-ash.json"); //TODO Update Ash ABI

const contractAddress = "0x1aD733D8196D9FfaCaaA9eADdC0da07727A4f47B";

export const setSph3reData = async () => {

    window.contract = await new web3.eth.Contract(contractABI, contractAddress);

    let amounts = [];

    let tokens = await axios({
        method: 'get',
        url: 'https://thesph3res.goneon.lu/parseSph3res.php',
        responseType: 'json'
      })
        .then(function (response) {
         return response.data;
        });

    var BreakException = {};
    await tokens.forEach(function(e){ 
        let tId = parseInt(e.id.tokenId);   

        setIsFrozenDB(tId);
        setNameDB(tId);

    });



};

export const setIsFrozenDB = async (i) => {
    return await window.contract.methods
        .getIsSphereFrozen(i)
        .call(function (error, result) {
            if (!error){                
                let setFrozen = axios({
                    method: 'get',
                    url: 'https://thesph3res.goneon.lu/setFrozen.php?tokenid='+i+'&frozen='+result,
                    responseType: 'json'
                  })
                    .then(function (response) {
                     return response.data;
                    });
            }
            else return -1;
        })
};

export const setNameDB = async (i) => {
    return await window.contract.methods
        .getName(i)
        .call(function (error, result) {
            if (!error){                
                let setFrozen = axios({
                    method: 'get',
                    url: 'https://thesph3res.goneon.lu/setName.php?tokenid='+i+'&name='+result,
                    responseType: 'json'
                  })
                    .then(function (response) {
                     return response.data;
                    });
            }
            else return -1;
        })
};

export const getSph3reData = async () => {

    let tokens = await axios({
        method: 'get',
        url: 'https://thesph3res.goneon.lu/getSph3res.php',
        responseType: 'json'
      })
        .then(function (response) {
         return response.data;
        });

    return tokens;



};
